<template>
    <Head>
        <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <Link rel="manifest" href="/site.webmanifest" />
        <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <Meta name="apple-mobile-web-app-title" content="Snubn&iacute;Prsteny4u.eu" />
        <Meta name="application-name" content="Snubn&iacute;Prsteny4u.eu" />
        <Meta name="msapplication-TileColor" content="#da532c" />
        <Meta name="theme-color" content="#ffffff" />
    </Head>

    <Body class="font-primary text-primary">
        <NuxtLoadingIndicator :duration="5000" color="repeating-linear-gradient(to right,#b54761 0%,#ffcad4 100%)" />
        <OrganismsGlobalTopBar v-if="false" :is-enabled="!!topBarData" :text-color="topBarData.textColor" :background-color="topBarData.backgroundColor" :html="topBarData.text" :campaign="topBarData.campaign"></OrganismsGlobalTopBar>
        <OrganismsGlobalAppHeader></OrganismsGlobalAppHeader>
        <slot></slot>
        <OrganismsGlobalAppFooter></OrganismsGlobalAppFooter>
    </Body>
</template>
<script setup>

if (false && !useState('topBar').value) {
    useState('topBar').value = (await useApiFetch(`/api/topBar?languageId=${useLocale().getLocale().languageId}`)).data.value.topBar;
}

const topBarData = useState('topBar').value;

</script>